var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ContainerLayout from '../../../components/ContainerLayout';
import { PATH_DASHBOARD } from '../../../routes/paths';
import PaymentNotesForm from '../../../sections/@dashboard/PaymentNotes/PaymentNotesForm';
var PaymentNoteNew = function () { return (_jsx(ContainerLayout, __assign({ helmet: "Payment Note: Create payment note", heading: "Create payment note", links: [
        {
            name: 'Payment Note',
            href: PATH_DASHBOARD.configuration.paymentNotes.root,
        },
        { name: 'New payment note' },
    ] }, { children: _jsx(PaymentNotesForm, {}) }))); };
export default PaymentNoteNew;
